import FaqToggle from './FaqToggle';

export default () => {
  let i = 0;

  const anchorLinks = () => {
    const checkActiveAnchorOnScroll = () => {
      const links = document.querySelectorAll('[faq-anchor-ref]');
      const sections = Array.from(links).map((link) => {
        const anchorRef = link.getAttribute('faq-anchor-ref');
        return document.querySelector(`#${anchorRef}`);
      });

      const addActiveClass = (link) => {
        link.classList.add('is-active');
      };

      const removeActiveClass = () => {
        links.forEach((link) => {
          link.classList.remove('is-active');
        });
      };

      const onScroll = () => {
        let activeLink = null;

        sections.forEach((section, index) => {
          const rect = section.getBoundingClientRect();
          if (rect.top <= 115 && rect.bottom >= 115) {
            activeLink = links[index];
          }
        });

        if (activeLink) {
          removeActiveClass();
          addActiveClass(activeLink);
        }
      };

      window.addEventListener('scroll', onScroll);
    };

    // Call the function to initialize the scroll listener
    checkActiveAnchorOnScroll();

    const links = document.querySelectorAll('[faq-anchor-ref]');

    links.forEach((link) => {
      const anchorRef = link.getAttribute('faq-anchor-ref');
      const anchor = document.querySelector(`#${anchorRef}`);

      link.addEventListener('click', (e) => {
        e.preventDefault();

        const yOffset = -85;
        const y = anchor.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      });
    });
  };

  document.body.addEventListener('htmx:afterSwap', () => {
    // Make FaqToggle run only once

    const cmsItems = document.querySelectorAll('.faq-full_faq-list-item');

    i += 1;

    if (i == cmsItems.length) {
      FaqToggle();
      anchorLinks();
      document.querySelector('.faq-full_faq-list').style.opacity = 1;
    }
  });
};
