export default () => {
  const pricingObject = [];

  document.querySelectorAll('[pricing-object="package"]').forEach((item) => {
    const packageName = item.getAttribute('object-value');
    const checkoutProduction = item.getAttribute('checkout-production');
    const checkoutDevelopment = item.getAttribute('checkout-development');

    const addons = [];

    item.querySelectorAll('[pricing-object="addons"]').forEach((addon) => {
      const value = addon.getAttribute('object-value');
      addons.push(value);
    });

    const arrayObject = {
      package: packageName,
      addons,
      checkoutProduction,
      checkoutDevelopment,
    };

    pricingObject.push(arrayObject);
  });

  return pricingObject;
};
