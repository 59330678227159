import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default () => {
  gsap.registerPlugin(ScrollTrigger);

  const mm = gsap.matchMedia();

  mm.add('(min-width: 992px)', () => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.section_lp-steps',
        pin: true,
        start: 'top top',
        end: '+=2000',
        scrub: 1,
        markers: false,
      },
    });

    tl.addLabel('start')
      .to('#step-number-1', { color: '#00162D' })
      .to('#step-line-1', { height: '100%' })
      .to('#step-number-2', { color: '#00162D' })
      .to('#step-text-container-1', { opacity: 0 }, '<')
      .to('#steps-image-1', { opacity: 0 }, '<')
      .to('#step-text-container-2', { opacity: 1 }, '<0.5')
      .to('#steps-image-2', { opacity: 1 }, '<')
      .to('#step-line-2', { height: '100%' })
      .to('#step-number-3', { color: '#00162D' })
      .to('#step-text-container-2', { opacity: 0 }, '<')
      .to('#steps-image-2', { opacity: 0 }, '<')
      .to('#step-text-container-3', { opacity: 1 }, '<0.5')
      .to('#steps-image-3', { opacity: 1 }, '<')
      .addLabel('end');
  });

  document.addEventListener('DOMContentLoaded', () => {
    const lazyImages = Array.from(document.querySelectorAll('img'));
    lazyImages.forEach((lazyImage) => {
      lazyImage.addEventListener('load', () => {
        ScrollTrigger.refresh();
      });
    });
  });
};
