export default () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlParams.entries());

  const paramsCheckArray = Object.entries(params);

  const finalParamsFt = [];
  const finalParamsLt = [];

  paramsCheckArray.forEach((element) => {
    if (element[0].includes('utm')) {
      const keyFt = `ft_${element[0]}`;
      const keyLt = `lt_${element[0]}`;

      finalParamsFt.push({ [keyFt]: element[1] });
      finalParamsLt.push({ [keyLt]: element[1] });
    }
  });

  const valuesObject = {
    ftpValue: localStorage.getItem('ftp'),
    ltpValue: localStorage.getItem('ltp'),
    activeSession: sessionStorage.getItem('activeltp'),
    passID: localStorage.getItem('passIDLtpFtp'),
  };

  if (!valuesObject.ftpValue) {
    localStorage.setItem('ftp', JSON.stringify(finalParamsFt));
    valuesObject.ftpValue = JSON.stringify(finalParamsFt);
  }

  if (!valuesObject.activeSession) {
    localStorage.setItem('ltp', JSON.stringify(finalParamsLt));
    valuesObject.ltpValue = JSON.stringify(finalParamsLt);
    sessionStorage.setItem('activeltp', true);
    valuesObject.activeSession = true;
  }
};
