import { reactive } from 'petite-vue';
import { personState } from './PersonPicker';

// eslint-disable-next-line
export const waitList = reactive({
  formData: {
    firstName: '',
    lastName: '',
    email: '',
    personNumber: '',
    package: '',
    packagePublicName: '',
    price: '',
    discountPrice: '',
    industry: '',
    initUserlURL: '',
    topicWordNumber: '',
    topicWordNumberNoun: 'Schulungsbereiche',
    hideTopicSelector: false,
  },
});
