import { computePosition, autoUpdate, arrow } from '@floating-ui/dom';
import gsap from 'gsap';

export default () => {
  const addon = document.querySelectorAll('[addon-item]');

  addon.forEach((addonItem) => {
    const addonHintButtons = addonItem.querySelector('[addon-hint-button]');

    const addonPopover = addonItem.querySelector('[addon-hint-popup]');

    const addonArrow = addonItem.querySelector('[addon-hint-arrow]');

    // Hide initial popover
    gsap.set(addonPopover, {
      autoAlpha: 0,
    });

    const updatePosition = () => {
      computePosition(addonHintButtons, addonPopover, {
        placement: 'top',
      }).then(({ x, y }) => {
        Object.assign(addonPopover.style, {
          left: `${x}px`,
          top: `${y - 10}px`,
        });
      });
    };

    const cleanup = autoUpdate(addonHintButtons, addonPopover, updatePosition);

    const hideAllPopovers = () => {
      document.querySelectorAll('[addon-hint-button]').forEach((popover) => {
        popover.setAttribute('open', 'false');

        gsap.set('[addon-hint-popup]', {
          autoAlpha: 0,
        });

        cleanup();
      });
    };

    const showPopover = () => {
      const buttonState = addonHintButtons.getAttribute('open');

      hideAllPopovers();

      autoUpdate(addonHintButtons, addonPopover, updatePosition);

      if (buttonState === 'true') {
        addonHintButtons.setAttribute('open', 'false');
        gsap.to(addonPopover, {
          autoAlpha: 0,
          duration: 0.3,
        });
      }

      if (buttonState === 'false' || buttonState === null) {
        addonHintButtons.setAttribute('open', 'true');
        gsap.to(addonPopover, {
          autoAlpha: 1,
          duration: 0.3,
        });
      }
    };

    addonHintButtons.addEventListener('click', showPopover);

    document.body.addEventListener('click', (event) => {
      hideAllPopovers();
    });
  });
};
