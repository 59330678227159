import Splide from '@splidejs/splide';
import gsap from 'gsap';

export default () => {
  if (document.querySelector('#course-content-slider')) {
    new Splide('#course-content-slider', {
      fixedWidth: '90%',
      gap: '1rem',
      focus: 'center',
    }).mount();
  }

  if (document.querySelector('#testimonial-slider-1')) {
    const testomonialSplideConfig = {
      type: 'loop',
      fixedWidth: '90%',
      mediaQuery: 'min',
      breakpoints: {
        992: {
          destroy: true,
        },
      },
    };

    const testimonialTabsControl = document.querySelectorAll('.testimonial_tab-link');
    const testimonialSlider1 = new Splide('#testimonial-slider-1', testomonialSplideConfig).mount();
    const testimonialSlider2 = new Splide('#testimonial-slider-2', testomonialSplideConfig).mount();

    testimonialTabsControl.forEach((tab) => {
      tab.addEventListener('click', () => {
        setTimeout(() => {
          testimonialSlider1.refresh();
          testimonialSlider2.refresh();
        }, 310);
      });
    });
  }
};
