export default () => {
  document.querySelectorAll('[nav-login-link]').forEach((button) => {
    const stagingURL = button.getAttribute('staging-url');
    const productionURL = button.getAttribute('production-url');

    if (window.location.hostname === 'is-fox.webflow.io') {
      button.href = stagingURL;
    } else {
      button.href = productionURL;
    }
  });
};
