import { createApp } from 'petite-vue';

export default () => {
  function Tabs(props) {
    return {
      activeTabText: '',
      toggleInitialTab() {
        const dropdownLists = document.querySelectorAll(
          '.courses-content_mobile-dropdown-cms-list'
        );

        dropdownLists.forEach((list, index) => {
          if (index === props.index) {
            const initialTab = list.querySelector('[tab-dropdown-slug]');
            this.activeTabText = initialTab.getAttribute('tab-dropdown-text');
          }
        });
      },
      toggleTab(element) {
        const tabSlug = element.getAttribute('tab-dropdown-slug');
        const tabText = element.getAttribute('tab-dropdown-text');

        document.querySelector(`[tabs-slug="${tabSlug}"]`).click();
        element.closest('.courses-content_mobile-dropdown').dispatchEvent(new Event('w-close'));
        this.activeTabText = tabText;
      },
      hideActiveTab(element) {
        const tabText = element.getAttribute('tab-dropdown-text');

        if (this.activeTabText === tabText) {
          element.classList.add('hide');
        } else {
          element.classList.remove('hide');
        }
      },
    };
  }

  createApp({
    Tabs,
  }).mount();
};
