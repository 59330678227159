import { createApp } from 'petite-vue';

export default () => {
  window.optibaseAddActiveVariantsListener((event) => {
    if (event.userFriendlyVariantId === 'nongated_control') {
      window.optibaseSendConversionEvent('demo_viewed');
    }
  });

  createApp({
    activeScreen: 'form',
    newsletter: false,
    allowAccess: false,
    form: {
      firstName: '',
      lastName: '',
      email: '',
      newsletterChecked: false,
      demoSlug: window.gatedDemoSlug,
      honey: false, // honeypot field
    },
    getURLParams() {
      const urlParams = new URLSearchParams(window.location.search);
      const allowAccessDemo = urlParams.get('demo-universal');

      if (allowAccessDemo === '45552') {
        this.setLocalStorage();
        this.allowAccess = true;
      }
    },
    setLocalStorage() {
      localStorage.setItem('demo-allow', true);
    },
    readLocalStorage() {
      const allow = localStorage.getItem('demo-allow');

      if (allow) {
        this.allowAccess = true;
        window.optibaseAddActiveVariantsListener(() => {
          window.optibaseSendConversionEvent('demo_viewed');
        });
      }
    },
    startup() {
      this.getURLParams();
      this.readLocalStorage();
    },
    sendForm() {
      const form = document.querySelector('#demo-gate-form');
      const formValidity = form.checkValidity();

      this.form.newsletterChecked = this.newsletter;

      if (!formValidity) {
        form.reportValidity();
      } else {
        (async () => {
          const rawResponse = await fetch(
            'https://hook.eu1.make.com/j9qahdpytfbepdsxqv5vv779144w6iqh',
            {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(this.form),
            }
          );
          const response = await rawResponse;

          this.handleFormResponse(response.status);
        })();
      }
    },
    handleFormResponse(statusCode) {
      if (statusCode === 200) {
        if (this.newsletter) {
          this.activeScreen = 'newsletter-register';
        }

        if (!this.newsletter) {
          this.activeScreen = 'normal-register';
        }
      }

      if (statusCode === 201) {
        this.activeScreen = 'existing-user';

        setTimeout(() => {
          this.setLocalStorage();
          this.allowAccess = true;
        }, 5000);
      }

      if (statusCode === 400) {
        this.activeScreen = 'error';
      }
    },
  }).mount('#demo-gate');
};
