import { createApp, reactive } from 'petite-vue';

export const personState = reactive({
  personNumber: new URLSearchParams(window.location.search).get('employeeNumber'),
  updateURL() {
    const url = new URL(window.location);
    url.searchParams.set('employeeNumber', this.personNumber);
    window.history.pushState(null, '', url.toString());
  },
});

export const PersonPicker = () => {
  createApp({
    personState,
    incrementNumber: 1,
    setInputWidth() {
      const input = document.querySelector('#employee-number-input');

      input.style.width = `${personState.personNumber.toString().length * 22}px`;
    },
    increment(mode) {
      if (isNaN(personState.personNumber)) {
        personState.personNumber = 1;
      }

      personState.personNumber = parseInt(personState.personNumber);

      if (mode === 'increase') {
        personState.personNumber += this.incrementNumber;
        personState.updateURL();
      }

      if (mode === 'decrease') {
        if (personState.personNumber >= 2) {
          personState.personNumber -= this.incrementNumber;
          personState.updateURL();
        }
      }

      this.setInputWidth();
    },
    correctNumber() {
      if (isNaN(personState.personNumber)) {
        personState.personNumber = 1;
      }

      if (personState.personNumber < 1) {
        personState.personNumber = 1;
      }
    },
  }).mount('#person-picker');
};
