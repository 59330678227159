import { createApp, reactive } from 'petite-vue';
import { popupState } from '../Popup/Popup';
import { personState } from '../Pricing/PersonPicker';
import { waitList } from '../Pricing/WaitList';
import getPricingObject from '../Pricing/getPricingObject';

export const interestTopics = reactive({
  array: [],
  clean() {
    document.querySelectorAll('.questions_checkbox-check-icon').forEach((icon) => {
      icon.classList.remove('is-active');
      icon.parentElement.classList.remove('is-active');
    });

    document.querySelectorAll('.questions_checkbox-icon').forEach((checkbox) => {
      checkbox.checked = false;
    });

    this.array = [];
  },
  maxTopics: 3,
});

export const checkoutButton = reactive({
  interestTopics,
  unlock: false,
  check() {
    if (waitList.formData.package === 'Complete') {
      this.unlock = true;
    } else {
      this.unlock = false;
    }

    if (interestTopics.array.length === interestTopics.maxTopics) {
      this.unlock = true;
    } else {
      this.unlock = false;
    }
  },
});

export default () => {
  const questionForm = document.querySelector('#question-form');

  if (questionForm) {
    questionForm.addEventListener('keypress', (e) => {
      const key = e.charCode || e.keyCode || 0;
      if (key === 13) {
        e.preventDefault();
      }
    });
  }

  createApp({
    activeGroup: 1,
    //  interestTopics: [],
    employeeNumber: '',
    industry: 'Nicht angegeben',
    initUserlURL: sessionStorage.getItem('init-userURL'),
    pricingRedirectFlag: false,
    getDomainLanguage() {
      const firstPath = location.pathname.split('/')[1];

      if (firstPath === 'en') {
        return 'en';
      }
      return 'de';
    },
    popupState,
    waitList,
    interestTopics,
    checkoutButton,
    submitting: false,
    toggleCheckbox(element) {
      const checkbox = element.querySelector('input[type="checkbox"]');
      const checkboxValue = element.getAttribute('checkbox-value');
      const icon = element.querySelector('.questions_checkbox-check-icon');
      const wrapper = element.querySelector('.questions_checkbox-inner-wrapper');

      const addValue = (value) => {
        interestTopics.array.push(value);
        try {
          window.optibaseSendConversionEvent('thema_gewhlt');
        } catch (error) {
          console.warn('Optibase Problem', error);
        }
      };

      const removeValue = (value) => {
        interestTopics.array = interestTopics.array.filter((topic) => topic !== value);
      };

      if (checkbox.checked) {
        icon.classList.add('is-active');
        wrapper.classList.add('is-active');
        addValue(checkboxValue);
      }

      if (!checkbox.checked) {
        icon.classList.remove('is-active');
        wrapper.classList.remove('is-active');
        removeValue(checkboxValue);
      }

      checkoutButton.check();
    },
    nextGroup() {
      this.activeGroup += 1;
      this.initUserlURL = sessionStorage.getItem('init-userURL');
    },
    previousGroup() {
      this.activeGroup -= 1;
    },
    handleEmployeeNumber(event) {
      this.employeeNumber = parseInt(event.target.value);

      if (event.target.value === '') {
        this.employeeNumber = 1;
      }

      if (event.target.value < 1) {
        this.employeeNumber = 1;
      }

      personState.personNumber = this.employeeNumber;
    },
    submitForm() {
      this.initUserlURL = sessionStorage.getItem('init-userURL');

      const data = {
        interestTopics: JSON.parse(JSON.stringify(interestTopics.array)),
        employeeNumber: this.employeeNumber,
        industry: this.industry,
      };

      document.querySelector('#question-form').addEventListener('submit', () => {
        const domain = window.location.origin;

        let redirectUrl = `${domain}/plans?addons=`;

        if ((window.location.pathname + window.location.search).split('/')[1] === 'en') {
          redirectUrl = `${domain}/en/plans?addons=`;
        }

        // if (this.pricingRedirectFlag) {
        //   redirectUrl = `${domain}/pricing-all?addons=''&redirect=false`;
        // }

        // if (
        //   (window.location.pathname + window.location.search).split('/')[1] === 'en' &&
        //   this.pricingRedirectFlag
        // ) {
        //   redirectUrl = `${domain}/en/pricing-all?addons=''&redirect=false`;
        // }

        data.interestTopics.forEach((topic, index) => {
          if (index === data.interestTopics.length - 1) {
            redirectUrl += `${topic}`;
          } else {
            redirectUrl += `${topic},`;
          }
        });

        redirectUrl += `&employeeNumber=${data.employeeNumber}&industry=${data.industry}`;

        window.location.replace(redirectUrl);
      });

      this.submitting = true;

      document.body.style.pointerEvents = 'none';

      document.querySelector('#question-form-submit-btn').click();
    },
    immediateRedirectExternalCheckout(state) {
      if (state === true) {
        if (waitList.formData.package === 'Complete') {
          this.redirectExternalCheckout();
        }
      }
    },
    redirectExternalCheckout() {
      let domain;

      const getMatchingUrl = () => {
        const pricingObject = getPricingObject();

        function filterPricingObject(condition) {
          return pricingObject.filter((item) => {
            // Filter by package name
            if (condition.package && item.package !== condition.package) {
              return false;
            }

            // Filter by exact match of addons
            if (condition.addons) {
              const addonsMatch =
                condition.addons.length === item.addons.length &&
                condition.addons.every((addon) => item.addons.includes(addon));
              if (!addonsMatch) {
                return false;
              }
            }

            return true;
          });
        }

        const filteredPricing = filterPricingObject({
          package: waitList.formData.package,
          addons: this.interestTopics.array,
        });

        if (window.location.hostname === 'is-fox.webflow.io') {
          domain = filteredPricing[0].checkoutDevelopment;
        }

        if (window.location.hostname === 'get.is-fox.com') {
          domain = filteredPricing[0].checkoutProduction;
        }

        /*
        if (waitList.formData.package === 'Starter') {
          if (interestTopics.array.includes('arbeitssicherheit')) {
            domain = 'https://my-uat.is-fox.com/register?priceId=price_1Ppop1RqCu6CCU9LJDaVDc56';
          }

          if (interestTopics.array.includes('cyber-security')) {
            domain = 'https://my-uat.is-fox.com/register?priceId=price_1PpoltRqCu6CCU9L1YNFWUu9';
          }

          if (interestTopics.array.includes('compliance')) {
            domain = 'https://my-uat.is-fox.com/register?priceId=price_1PpojcRqCu6CCU9L56s7OsFG';
          }

          if (interestTopics.array.includes('datenschutz')) {
            domain = 'https://my-uat.is-fox.com/register?priceId=price_1PponHRqCu6CCU9LsE8asrR4';
          }
        }

        if (waitList.formData.package === 'Professional') {
          if (
            interestTopics.array.includes('datenschutz') &&
            interestTopics.array.includes('arbeitssicherheit')
          ) {
            domain = 'https://my-uat.is-fox.com/register?priceId=price_1PpohDRqCu6CCU9LoeT6y07r';
          }

          if (
            interestTopics.array.includes('cyber-security') &&
            interestTopics.array.includes('arbeitssicherheit')
          ) {
            domain = 'https://my-uat.is-fox.com/register?priceId=price_1Ppog3RqCu6CCU9LSQyxcTeP';
          }

          if (
            interestTopics.array.includes('compliance') &&
            interestTopics.array.includes('arbeitssicherheit')
          ) {
            domain = 'https://my-uat.is-fox.com/register?priceId=price_1PpoeqRqCu6CCU9LpJzqI4QH';
          }

          if (
            interestTopics.array.includes('cyber-security') &&
            interestTopics.array.includes('datenschutz')
          ) {
            domain = 'https://my-uat.is-fox.com/register?priceId=price_1PpodVRqCu6CCU9LzCRBODpR';
          }

          if (
            interestTopics.array.includes('datenschutz') &&
            interestTopics.array.includes('compliance')
          ) {
            domain = 'https://my-uat.is-fox.com/register?priceId=price_1PpobwRqCu6CCU9LwOiiyRfU';
          }

          if (
            interestTopics.array.includes('compliance') &&
            interestTopics.array.includes('cyber-security')
          ) {
            domain = 'https://my-uat.is-fox.com/register?priceId=price_1PpoaJRqCu6CCU9Lvqvf46ux';
          }
        }

        if (waitList.formData.package === 'Complete') {
          domain = 'https://my-uat.is-fox.com/register?priceId=price_1PpoXORqCu6CCU9Ln64ViZtc';
        }
          */
      };

      getMatchingUrl();

      if (domain === undefined) {
        console.warn('No matching Plan URL found');
        return;
      }

      domain += `&quantity=${personState.personNumber}`;

      const domainLanguage = this.getDomainLanguage();

      domain += `&lang=${domainLanguage}`;

      window.location.href = domain;
    },
  }).mount('#question-popup');
};
