import { createApp } from 'petite-vue';
import currency from 'currency.js';
import { personState } from './PersonPicker';
import { popupState } from '../Popup/Popup';
import { waitList } from './WaitList';
import { interestTopics, checkoutButton } from '../Question/QuestionPopup';

export default () => {
  const getUrlAddons = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const addons = urlParams.get('addons');

    // Return null if addons are not set
    if (!addons) {
      return null;
    }

    return addons.split(',');
  };

  const getIndustry = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let industry = urlParams.get('industry');
    industry = decodeURI(industry);

    return industry;
  };

  const getEmployeeNumber = () => {
    let employeeNumber = new URLSearchParams(window.location.search).get('employeeNumber');

    // Actucally not necessary, correctNumber() in Personpicker can also do and will set it to 1
    if (employeeNumber < 1) {
      employeeNumber = 1;
    }

    return employeeNumber;
  };

  const checkRedirect = () => {
    const redirectFlag = new URLSearchParams(window.location.search).get('redirect');

    return redirectFlag;
  };

  // Redirect to price calculator if industry is not set
  if (getIndustry() === 'null' || !getUrlAddons() || !getEmployeeNumber()) {
    if (checkRedirect() !== 'false') {
      window.location.replace('/price-caculator');
    }
  }

  const euro = (value) =>
    currency(value, {
      symbol: '€',
      separator: '.',
      decimal: ',',
      fromCents: false,
    });

  createApp({
    addons: getUrlAddons(),
    priceIndex: 0,
    priceTotal: 0,
    industry: getIndustry(),
    personState,
    popupState,
    waitList,
    interestTopics,
    checkoutButton,
    setPriceIndex() {
      switch (true) {
        case personState.personNumber <= 9:
          this.priceIndex = 0;
          break;
        case personState.personNumber <= 49:
          this.priceIndex = 1;
          break;
        case personState.personNumber <= 249:
          this.priceIndex = 2;
          break;
        case personState.personNumber >= 250:
          this.priceIndex = 3;
          break;
        default:
          this.priceIndex = 0;
      }
    },
    displaySinglePrice(price) {
      this.setPriceIndex();
      return euro(price[this.priceIndex]).format();
    },
    displayMultipliedPrice(price) {
      this.setPriceIndex();
      return euro(price[this.priceIndex]).multiply(personState.personNumber).format();
    },
    filterPriceItem(item) {
      const itemAddons = item.querySelectorAll('[pricing-item="addon"]');
      const itemAddonSlugs = [];
      const showAlways = item.getAttribute('show-always');

      itemAddons.forEach((addon) => {
        const slug = addon.getAttribute('addon-slug');

        itemAddonSlugs.push(slug);
      });

      function arrayCompare(_arr1, _arr2) {
        if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) {
          return false;
        }

        // .concat() to not mutate arguments
        const arr1 = _arr1.concat().sort();
        const arr2 = _arr2.concat().sort();

        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) {
            return false;
          }
        }

        return true;
      }

      if (!arrayCompare(itemAddonSlugs, this.addons) && showAlways !== 'true') {
        item.remove();
      }
    },

    showWaitlist(packageName, price, packageTopicCount, packagePublicName) {
      this.setPriceIndex();
      waitList.formData.price = euro(price[this.priceIndex])
        .multiply(personState.personNumber)
        .format();

      // 20% discount
      waitList.formData.discountPrice = euro(waitList.formData.price).multiply(0.8).format();

      waitList.formData.package = packageName;
      waitList.formData.personNumber = personState.personNumber;
      waitList.formData.industry = this.industry;
      waitList.formData.initUserlURL = sessionStorage.getItem('init-userURL');
      waitList.formData.packagePublicName = packagePublicName;

      interestTopics.maxTopics = packageTopicCount;

      if (packageTopicCount === 1) {
        waitList.hideTopicSelector = false;
        waitList.formData.topicWordNumber = 'einen';
        waitList.formData.topicWordNumberNoun = 'Schulungsbereich';
      }

      if (packageTopicCount === 2) {
        waitList.hideTopicSelector = false;
        waitList.formData.topicWordNumber = 'zwei';
        waitList.formData.topicWordNumberNoun = 'Schulungsbereiche';
      }

      if (packageTopicCount === 0) {
        waitList.hideTopicSelector = true;
      }

      popupState.togglePopup();
    },
  }).mount('#pricing-component');
};
