import { createApp, reactive } from 'petite-vue';

export const popupState = reactive({
  showPopup: false,
  togglePopup() {
    if (this.showPopup) {
      this.showPopup = false;

      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';

      return;
    }

    if (!this.showPopup) {
      this.showPopup = true;

      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
    }
  },
});

export const popupApp = () => {
  createApp({ popupState }).mount('#popup-toggle');
  createApp({ popupState }).mount('#popup-toggle-1');
  createApp({ popupState }).mount('#popup-toggle-1-2');
  createApp({ popupState }).mount('#popup-toggle-2');
  createApp({ popupState }).mount('#popup-toggle-2-2');
  createApp({ popupState }).mount('#popup-toggle-3');
  createApp({ popupState }).mount('#popup-toggle-3-2');
  createApp({ popupState }).mount('#popup-toggle-4');
  createApp({ popupState }).mount('#popup-toggle-4-2');
  createApp({ popupState }).mount('#popup-toggle-5');
  createApp({ popupState }).mount('#popup-toggle-5-2');
  createApp({ popupState }).mount('#popup-toggle-6');
  createApp({ popupState }).mount('#popup-toggle-6-2');
  createApp({ popupState }).mount('#popup-toggle-cta-banner');
  createApp({ popupState }).mount('#popup-toggle-price-banner');
};
