import InfiniteMarquee from 'infinite-marquee';
import gsap from 'gsap';

export default () => {
  const mm = gsap.matchMedia();

  mm.add('(max-width: 991px)', () => {
    const marquee = new InfiniteMarquee({
      el: document.querySelector('.marquee'),
      direction: 'right',
      duration: 20,
      css: false,
    });

    marquee; //eslint-disable-line
  });
};
